// vue
import { ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type { Nullable, Subject } from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const useSubjectStore = defineStore('subject', () => {
  // fetch repositories
  const { $subjects } = useNuxtApp()

  // state
  const subject = ref<Nullable<Subject>>(null)
  const subjects = ref<Subject[]>([])

  // actions
  async function show (
    id: number,
    config?: NitroFetchOptions<string>
  ) {
    const { subject: _subject } =
      await $subjects.show<{ subject: Subject }>(id, config)
    subject.value = _subject
    return subject
  }

  async function index (
    config?: NitroFetchOptions<string>
  ) {
    const _subjects =
      await $subjects.index<Subject[]>(config)
    subjects.value = _subjects
    return subjects
  }

  return {
    subject,
    subjects,
    index,
    show
  }
})
